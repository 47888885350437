import React, {useEffect, useState} from 'react';
import {BiArrowBack} from "react-icons/bi";
import {useLocation, useNavigate} from "react-router-dom";
import {AiOutlineArrowDown, AiOutlineArrowUp, AiOutlinePlus} from "react-icons/ai";
import CircleBtn from "../components/CircleBtn";
import accountService from "../services/accountService";
import {getAccountTypeFr, printError, roundValue} from "../services/Utils";
import LoadingEffect from "../components/LoadingEffect";
import HelpModal from "../modals/HelpModal";

const BalanceItem = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const account = location.state?.selectedAccount;
    const [fundingAccount, setFundingAccount] = useState();
    const [canWait, setCanWait] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const callBack = () => {
        setCanWait(false);
    }
    useEffect(() => {
        const fundingAccountResponse = accountService.getFundingAccount(account?.accountId);
        setCanWait(true);
        fundingAccountResponse.then(fundingAccount => {
            setFundingAccount(fundingAccount);
            callBack();
        }).catch(error => {
            callBack();
            printError(error);
        });
    }, []);

    const handleHelpModalShow = () => {
        setShowHelpModal(!showHelpModal);
    }

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-lg-6 d-flex justify-content-start"}>
                    <button className={"btn btn-info"} onClick={() => {
                        navigate("/client/account")
                    }}><span><i><BiArrowBack size={28}/></i></span> Retour
                    </button>
                </div>
            </div>
            {account && <div className={"row mt-5"}>
                <div className={"col-lg-4"}>
                    <h4>{account?.currency?.code} solde {getAccountTypeFr(account?.accountType?.accountType)}</h4>
                    <h1>{roundValue(account?.balance)} {account?.currency?.symbol}</h1>
                </div>
                {/*<CircleBtn onClick={() => navigate("/client/account/deposit/new", {state: {currentAccount: account}})}*/}
                {/*           icon={<AiOutlinePlus size={28}/>}*/}
                {/*           content={"Dépôt"}/>*/}
                <CircleBtn onClick={handleHelpModalShow}
                           icon={<AiOutlinePlus size={28}/>}
                           content={"Dépôt"}/>
                {/*<CircleBtn  onClick={() => navigate("/client/account/convert",{state:{currentAccount:account}})} icon={<TbArrowsExchange2 size={28}/>}
                           content={"Convert"}/>*/}
                <CircleBtn onClick={() => navigate("/client/account/send", {state: {currentAccount: account}})}
                           icon={<AiOutlineArrowUp size={28}/>}
                           content={"Envoie"}/>
                <CircleBtn onClick={() => navigate("/client/account/withdraw", {state: {currentAccount: account}})}
                           icon={<AiOutlineArrowDown size={28}/>}
                           content={"Retrait"}/>
            </div>}

            <hr/>
            {fundingAccount && <div className={"col-lg-4"}>
                <h4>{fundingAccount?.currency?.code} solde {getAccountTypeFr(fundingAccount?.accountType?.accountType)}</h4>
                <h1>{roundValue(fundingAccount?.balance)} {fundingAccount?.currency?.symbol}</h1>
            </div>}
            {
                canWait && <div className={"text-center"}><LoadingEffect/></div>
            }
            {showHelpModal && <HelpModal showModal={showHelpModal} handleModal={handleHelpModalShow}/>}
        </div>
    );
};

export default BalanceItem;