import React from 'react';

const HistoryHeader = () => {
    return (
        <div className={"row"}>
            <div className={"col-lg-6 d-flex justify-content-start"}>
                <h3>Historique des transactions</h3>
            </div>
        </div>
    );
};

export default HistoryHeader;