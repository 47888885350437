import React, {useState} from 'react';
import {roundValue} from "../services/Utils";
import TransactionStatusTdComponent from "./transactionStatusTdComponent";
import {FcCancel} from "react-icons/fc";
import accountService from "../services/accountService";

const TransactionHistoryCard = ({transaction,refresh}) => {
    const [canWait, setCanWait] = useState(false);

    const callBack = () => {
        setCanWait(false);
        refresh();
    }
    const handleClickCancelTransfer = () => {
        setCanWait(true);
        accountService.cancelTransfer(transaction?.orderId, callBack).then(

        );
    }

    return (
        <div className={"col col-sm-auto col-md-auto col-lg-auto mt-2"}>
            <div className="card">
                <TransactionStatusTdComponent transaction={transaction}/>
                <div className="card-body">
                    <p className="card-text">
                        Type: {transaction?.type}
                    </p>
                    <p className="card-text">
                        Montant principal: {roundValue(transaction?.amount)}
                        <mark>{transaction?.currencySymbol}</mark>
                    </p>
                    <p className="card-text">
                        Montant transféré: {roundValue(transaction?.paidAmount)}
                        <mark>{transaction?.currencySymbol}</mark>
                    </p>
                    <p className="card-text">
                        Destinataire: {transaction?.recipient}
                    </p>
                    <p className="card-text">
                        Référence: {transaction?.reference === "" ? "Pas de ref" : transaction.reference}
                    </p>
                    <p className="card-text">
                        Note: {transaction?.note === "" ? "Pas de note" : transaction.note}
                    </p>
                    <div className="card-footer text-muted text-center">
                        {transaction?.createdAt ? transaction.createdAt : "No date"}
                        {canWait && <span>...</span>}
                        {!canWait && transaction?.type === 'transfert' && (transaction?.status === "requested" || transaction?.status === "partially") &&
                            <span className={"ms-2"}><FcCancel size={24} onClick={handleClickCancelTransfer}/></span>}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TransactionHistoryCard;