import React from 'react';

const BackToCountryMainPageComponent = ({text}) => {
    return (
        <div className={"container"}>
            <h2 className={"text-danger"}>{text}</h2>
        </div>
    );
};

export default BackToCountryMainPageComponent;