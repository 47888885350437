import React from 'react';

const PriTransWebSiteLinkComponent = () => {
    return (
        <a href={"https://pri-trans.com"}>
            https://pri-trans.com
        </a>
    );
};

export default PriTransWebSiteLinkComponent;